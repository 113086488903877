<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt--6">
      <table-navigation :table-title="tableTitle"
                        :table-buttons="tableButtons"
                        :table-columns="tableColumns"
                        :table-data="tableData"
                        :router-view="routerView"
                        :connection-error="connectionError"
                        :pagination="pagination"
                        :filter-options="filterOptions"
                        :filters="tableFilters"
                        @edit="handleEdit"
                        @delete="handleDelete"
                        @page="changePage"
                        @filters="changeFilters"
      >
      </table-navigation>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import TableNavigation from "@/views/Store/Tables/TableNavigation";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";

const {mapState, mapActions} = createNamespacedHelpers('CouponListModule')

export default {
  name: 'CouponList',
  components: {
    BreadcrumbHeader,
    TableNavigation,
  },
  data() {
    return {
      tableTitle: 'Lista kuponów',
      routerView: 'coupon-view',
      tableButtons: [
        {
          label: 'Dodaj kupon',
          icon: 'fas fa-edit',
          link: 'coupon-add',
        },
      ],
      tableColumns: [
        {
          prop: 'name',
          label: 'Nazwa',
          minWidth: 140,
          sortable: true,
          link: true,
        },
        {
          prop: 'code',
          label: 'Kod',
          minWidth: 100,
          sortable: true,
          link: true,
        },
        {
          prop: 'discount',
          label: 'Rabat %',
          minWidth: 60,
          sortable: false,
        },
        {
          prop: 'amount',
          label: 'Rabat kwotowy',
          minWidth: 60,
          sortable: false,
        },
        {
          prop: 'active',
          label: 'Aktywny',
          minWidth: 60,
          sortable: false,
        },
        {
          prop: 'actions',
          label: 'Edytuj',
          minWidth: 60,
          sortable: false,
          actions: [
            {
              code: 'edit',
              label: 'Edytuj'
            },
            {
              code: 'delete',
              label: 'Usuń'
            },
          ]
        }
      ],
      filterOptions: [
        {field: 'code', label: 'Kod'},
        {field: 'active', label: 'Aktywny'},
        {field: 'freeShipping', label: 'Darmowa wysyłka'},
      ],
    }
  },
  computed: {
    ...mapState({
      tableData: state => state.items,
      totalItems: state => state.totalItems,
      tableFilters: state => state.filterValues,
      connectionError: state => state.apiConnectionErr
    }),
    breadcrumb() {
      return [
        {title: 'Promocje'},
        {title: 'Kody promocyjne'},
      ];
    },
    handleError() {
      return `Błąd ${this.connectionError.status} - ${this.$t[this.connectionError.status]}`;
    },
    pagination() {
      return {
        totalItems: this.totalItems,
        perPage: 20,
        currentPage: parseInt(this.$route.params.pageNum || 1),
      };
    },
  },
  methods: {
    changePage(page) {
      this.$router.push({name: 'coupon-list', params: {pageNum: page}});
    },
    changeFilters(filters) {
      this.$store.commit('CouponListModule/setFilterValues', filters);
      this.$store.dispatch('CouponListModule/fetchList', {
        token: this.$store.state.Auth.token,
        page: 1,
      });
    },
    handleEdit(id) {
      console.log(id)
      this.$router.push(`/coupon/${id}`)
    },
    async handleDelete(id) {
      await this.$store.dispatch('CouponListModule/deleteCoupon', { id })
      await this.$store.dispatch('CouponListModule/fetchList', {
        token: this.$store.state.Auth.token,
        page: this.$route.params.pageNum || 1,
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('CouponListModule/resetItems', []);
    return next()
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('CouponListModule/resetItems', []);
    this.$store.dispatch('CouponListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: to.params.pageNum || 1,
    });
    return next();
  },
  beforeCreate() {
    this.$store.dispatch('CouponListModule/fetchList', {
      token: this.$store.state.Auth.token,
      page: this.$route.params.pageNum || 1,
    });
  }
};
</script>
